import qs from 'qs'
import { MRT_SortingState } from 'material-react-table'
import http from '../../services/http'

export enum ERouletteSocketPlayerEventType {
  incoming = 'incoming',
  outgoing = 'outgoing',
  system = 'system',
}

export interface IRouletteSocketPlayerEvent {
  playerId: string
  login: string
  gameId: string | null
  sessionId: string
  type: ERouletteSocketPlayerEventType
  event: string
  data: any
  createdAt: Date
  id: string
}

class RouletteSocketPlayerEvents {
  prefix: string = '/api/v2/roulette-socket-player-event'

  getEvents = async (params?: {
    offset?: number
    limit?: number
    from?: string
    to?: string
    login?: string
    sort?: MRT_SortingState
  }): Promise<{
    count: number
    rows: IRouletteSocketPlayerEvent[]
    meta: {
      offset: number
      limit: number
    }
  }> => {
    const response = await http.get(this.prefix, {
      params,
      paramsSerializer: qs.stringify,
    })

    return response.data
  }
}

export default new RouletteSocketPlayerEvents()
