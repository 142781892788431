const routes = {
  login: '/login',
  gameSchedule: '/',
  operators: '/operators',
  players: '/players',
  recordings: '/recordings',
  recordingWithId: '/recordings/:recordId',
  balanceSheetRequests: '/balance-sheet-requests',
  limits: '/limits',
  bonuses: '/bonuses',
  stands: '/stands',
  rouletteSocketPlayerEvents: '/roulette-socket-player-events/',
  manageGameRouletteById: 'game/roulette/:gameId',
  manageGameRoulette: 'game/roulette',
  historyRouletteById: 'history/roulette/:gameId',
  historyRoulette: 'history/roulette',
  transactionPlayer: 'transactions/player',
  transactionPlayerById: 'transactions/player/:playerId',
  playerFinancialHistory: '/player/:playerId/finance',
  playerGameSessionResults: '/player/:playerId/game/results',
  playerTransactions: '/player/:playerId/transactions',
  detailRouletteSession: '/game/:gameId/session/:sessionId/detail',
}

export default routes
