import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Route, Routes } from 'react-router-dom'
import { AdminLayout, AuthLayout, Common } from '../layouts'
import routes from './routes'
import {
  BalanceSheetRequest,
  Games,
  HistoryRoulette,
  Limits,
  Login,
  ManageGameRoulette,
  NoMatch,
  Operators,
  Players,
  Recording,
  Recordings,
  RouletteSocketPlayerEvents,
  Stands,
} from '../pages'
import RoleAccess from './RoleAccess'
import { useStore } from '../stores'
import PrivateRoute from './PrivateRoute'
import {
  DetailHistoryRouletteDialog,
  PlayerFinancialHistoryDialog,
  PlayerGameSessionResultsDialog,
  PlayerTransactionsDialog,
} from '../components'

const Router: FC = observer(() => {
  const { loginStore } = useStore()

  return (
    <Routes>
      <Route element={<Common />}>
        <Route element={<AuthLayout />}>
          <Route path={routes.login} element={<Login />} />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route element={<AdminLayout />}>
            <Route index element={<Games />} />
            <Route
              path={routes.operators}
              element={
                <RoleAccess isAllowed={loginStore.isRoleAM}>
                  <Operators />
                </RoleAccess>
              }
            />
            <Route
              path={routes.players}
              element={
                <RoleAccess isAllowed={loginStore.isRoleAM}>
                  <Players />
                </RoleAccess>
              }
            />
            <Route
              path={routes.recordings}
              element={
                <RoleAccess isAllowed={loginStore.isRoleAM}>
                  <Recordings />
                </RoleAccess>
              }
            />
            <Route
              path={routes.recordingWithId}
              element={
                <RoleAccess isAllowed={loginStore.isRoleAM}>
                  <Recording />
                </RoleAccess>
              }
            />
            <Route
              path={routes.balanceSheetRequests}
              element={
                <RoleAccess isAllowed={loginStore.isRoleAM}>
                  <BalanceSheetRequest />
                </RoleAccess>
              }
            />
            <Route
              path={routes.limits}
              element={
                <RoleAccess isAllowed={loginStore.isRoleAM}>
                  <Limits />
                </RoleAccess>
              }
            />
            <Route
              path={routes.stands}
              element={
                <RoleAccess isAllowed={loginStore.isRoleAM}>
                  <Stands />
                </RoleAccess>
              }
            />
            <Route
              path={routes.historyRouletteById}
              element={
                <RoleAccess isAllowed={loginStore.isRoleAM}>
                  <HistoryRoulette />
                </RoleAccess>
              }
            />

            <Route
              path={`${routes.playerFinancialHistory}`}
              element={
                <RoleAccess isAllowed={loginStore.isRoleAM}>
                  <PlayerFinancialHistoryDialog />
                </RoleAccess>
              }
            />
            <Route
              path={routes.playerGameSessionResults}
              element={
                <RoleAccess isAllowed={loginStore.isRoleAM}>
                  <PlayerGameSessionResultsDialog />
                </RoleAccess>
              }
            />
            <Route
              path={routes.playerTransactions}
              element={
                <RoleAccess isAllowed={loginStore.isRoleAM}>
                  <PlayerTransactionsDialog />
                </RoleAccess>
              }
            />
            <Route
              path={routes.detailRouletteSession}
              element={
                <RoleAccess isAllowed={loginStore.isRoleAM}>
                  <DetailHistoryRouletteDialog />
                </RoleAccess>
              }
            />
            <Route
              path={routes.rouletteSocketPlayerEvents}
              element={
                <RoleAccess isAllowed={loginStore.isRoleAM}>
                  <RouletteSocketPlayerEvents />
                </RoleAccess>
              }
            />
          </Route>

          <Route path={routes.manageGameRouletteById} element={<ManageGameRoulette />} />
        </Route>

        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  )
})

export default Router
