import { FC, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_SortingState,
  useMaterialReactTable,
} from 'material-react-table'
import { useInfiniteQuery } from 'react-query'
import { toast } from 'react-toastify'
import { DateRange } from 'react-day-picker'
import { endOfDay, format, startOfMonth } from 'date-fns'
import { Button, Stack } from '@mui/material'
import { useInView } from 'react-intersection-observer'
import { Link, useSearchParams } from 'react-router-dom'
import { ApiRouletteSocketPlayerEvents } from '../../api'
import mrtLocalization from '../../constants/mrtLocalization'
import { ERouletteSocketPlayerEventType, IRouletteSocketPlayerEvent } from '../../api/rouletteSocketPlayerEvents'
import { EDateFormat, formatToMoscowTZ } from '../../utils/date'
import { DateRangePicker, LoadMoreRows } from '../../components'
import { useStore } from '../../stores'
import { EDrawerView } from '../../stores/DrawerCRUD.store'

const fetchSize = 25
const initFromDate = startOfMonth(new Date())
const initToDate = endOfDay(new Date())
const initSort: MRT_SortingState = [
  {
    id: 'createdAt',
    desc: true,
  },
]

const RouletteSocketPlayerEvents: FC = observer(() => {
  const { drawerCRUDStore } = useStore()
  const { ref, inView } = useInView()

  const [searchParams, setSearchParams] = useSearchParams()

  const [range, setRange] = useState<DateRange>(() => {
    const fromParam = searchParams.get('from')
    const toParam = searchParams.get('to')
    const from = fromParam ? new Date(fromParam) : initFromDate
    const to = toParam ? new Date(toParam) : initToDate

    return { from, to }
  })
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(() => {
    const filtersParam = searchParams.get('filters')

    if (filtersParam) {
      try {
        return JSON.parse(filtersParam)
      } catch (e) {
        return []
      }
    }
    return []
  })

  const [columnSorting, setColumnSorting] = useState<MRT_SortingState>(() => {
    const sortingParam = searchParams.get('sorting')

    if (sortingParam) {
      try {
        return JSON.parse(sortingParam)
      } catch (e) {
        return initSort
      }
    }

    return initSort
  })

  useEffect(() => {
    const newParams: Record<string, string> = {}

    if (range.from) {
      newParams.from = format(range.from, EDateFormat.yyy_MM_dd_HH_mm_ss)
    }
    if (range.to) {
      newParams.to = format(range.to, EDateFormat.yyy_MM_dd_HH_mm_ss)
    }

    if (columnFilters.length) {
      newParams.filters = JSON.stringify(columnFilters)
    }
    if (columnSorting.length) {
      newParams.sorting = JSON.stringify(columnSorting)
    }

    setSearchParams(newParams, { replace: true })
  }, [columnFilters, columnSorting, range.from, range.to])

  const { data, isLoading, isFetching, isError, refetch, fetchNextPage, isFetchingNextPage } = useInfiniteQuery(
    ['get player socket events', columnFilters, columnSorting],
    async ({ pageParam = 0 }) => {
      const filters = columnFilters.reduce((acc, item) => {
        acc[item.id] = String(item.value)
        return acc
      }, {} as Record<string, string>)

      const query = {
        ...filters,
      }

      if (range?.from) query.from = format(range?.from, EDateFormat.yyy_MM_dd_HH_mm)
      if (range?.to) query.to = format(range?.to, EDateFormat.yyy_MM_dd_HH_mm)

      return ApiRouletteSocketPlayerEvents.getEvents({
        limit: fetchSize,
        offset: pageParam * fetchSize,
        sort: columnSorting,
        ...query,
      })
    },
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => allPages.length,
      keepPreviousData: true,
      onError: (err) => toast.error(`${err}`),
    }
  )

  const columns = useMemo<MRT_ColumnDef<IRouletteSocketPlayerEvent>[]>(
    () => [
      {
        id: 'createdAt',
        accessorKey: 'createdAt',
        header: 'Время',
        sortingFn: 'datetime',
        sortDescFirst: true,
        sortUndefined: 'first',
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          return formatToMoscowTZ(cell.getValue<Date>())
        },
      },
      {
        id: 'login',
        accessorKey: 'login',
        header: 'Игрок',
        enableSorting: false,
      },
      {
        id: 'event',
        accessorKey: 'event',
        header: 'Событие',
        enableSorting: false,
      },
      {
        id: 'type',
        accessorKey: 'type',
        header: 'Направление',
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          const valueType = cell.getValue<ERouletteSocketPlayerEventType>()

          const valueName = {
            [ERouletteSocketPlayerEventType.system]: 'служебное',
            [ERouletteSocketPlayerEventType.incoming]: 'получено',
            [ERouletteSocketPlayerEventType.outgoing]: 'отправлено',
          }

          return valueName[valueType]
        },
      },
      {
        id: 'gameId',
        accessorKey: 'gameId',
        header: 'Игра',
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          const { gameId } = row.original

          if (!gameId) return '-'

          return (
            <a
              href={`/game/edit/${gameId}`}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()

                drawerCRUDStore.changeView(EDrawerView.GAME_EDIT, gameId)
              }}>
              {gameId}
            </a>
          )
        },
      },
      {
        id: 'sessionId',
        accessorKey: 'sessionId',
        header: 'Сессия',
        enableSorting: false,
        Cell: ({ cell, row }) => {
          const sessionId = cell.getValue<number | null>()
          const { gameId } = row.original

          if (!sessionId || !gameId) return '-'

          return (
            <Link target="_blank" to={`/game/${gameId}/session/${sessionId}/detail`}>
              {sessionId}
            </Link>
          )
        },
      },
    ],
    []
  )

  const events = useMemo(() => data?.pages.flatMap((item) => item.rows ?? []) || [], [data])
  const eventsCount = events.length
  const eventsTotalCount = data?.pages?.[0]?.count || 0

  useEffect(() => {
    let delayTimeout: string | number | NodeJS.Timeout | undefined

    if (inView && eventsCount < eventsTotalCount && !isLoading && !isFetchingNextPage) {
      delayTimeout = setTimeout(() => {
        fetchNextPage()
      }, 300)
    }

    return () => clearTimeout(delayTimeout) // Очистка таймера при изменении inView
  }, [inView, isLoading, isFetchingNextPage, eventsCount, eventsTotalCount])

  const muiTable = useMaterialReactTable({
    columns,
    data: events,
    ...mrtLocalization,
    enableFullScreenToggle: false,
    enablePagination: false,
    enableGlobalFilter: false,
    manualFiltering: true,
    onColumnFiltersChange: setColumnFilters,
    manualSorting: true,
    onSortingChange: setColumnSorting,
    enableSortingRemoval: false,
    enableDensityToggle: false,
    rowCount: eventsTotalCount,
    state: {
      sorting: columnSorting,
      columnFilters,
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      showColumnFilters: true,
      density: 'compact',
    },
    renderDetailPanel: ({ row }) => {
      const detail = JSON.stringify(row.original.data, null, 2)
      return (
        <code>
          <pre>{detail}</pre>
        </code>
      )
    },
    renderTopToolbarCustomActions: () => {
      return (
        <Stack gap={2} direction="row" alignItems="center" flexWrap="wrap">
          <DateRangePicker
            from={range.from}
            to={range.to}
            onChangeRange={(newRange) =>
              setRange({
                from: newRange?.from,
                to: newRange?.to,
              })
            }
          />
          <Button size="small" variant="outlined" onClick={async () => refetch()}>
            Применить
          </Button>
        </Stack>
      )
    },
    renderBottomToolbarCustomActions: () => {
      return (
        <LoadMoreRows
          ref={ref}
          rows={eventsCount}
          totalRows={eventsTotalCount}
          isLoading={isFetchingNextPage}
          onLoad={() => {
            fetchNextPage()
          }}
        />
      )
    },
  })

  return <MaterialReactTable table={muiTable} />
})

export default RouletteSocketPlayerEvents
