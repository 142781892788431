import { FC } from 'react'
import { Box, ListItemIcon, ListItemText, MenuItem, MenuList, Tooltip } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ShieldIcon from '@mui/icons-material/Shield'
import PeopleIcon from '@mui/icons-material/People'
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary'
// import PriceChangeIcon from '@mui/icons-material/PriceChange'
import TableBarIcon from '@mui/icons-material/TableBar'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import routes from '../../router/routes'

const menu = [
  {
    name: 'Расписание игр',
    link: routes.gameSchedule,
    icon: <CalendarMonthIcon />,
  },
  {
    name: 'Операторы',
    link: routes.operators,
    icon: <ShieldIcon />,
  },
  {
    name: 'Игроки',
    link: routes.players,
    icon: <PeopleIcon />,
  },
  {
    name: 'Записи игры',
    link: routes.recordings,
    icon: <VideoLibraryIcon />,
  },
  // {
  //   name: 'Заявки по балансу',
  //   link: routes.balanceSheetRequests,
  //   icon: <PriceChangeIcon />,
  // },
  {
    name: 'Лимиты',
    link: routes.limits,
    icon: <LowPriorityIcon />,
  },
  {
    name: 'Стойки',
    link: routes.stands,
    icon: <TableBarIcon />,
  },
  {
    name: 'События игрока',
    link: routes.rouletteSocketPlayerEvents,
    icon: <SyncAltIcon />,
  },
]

type SideAdminMenuProps = {
  isFull: boolean
}

const SideAdminMenu: FC<SideAdminMenuProps> = ({ isFull = false }) => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Box>
      <MenuList>
        {menu.map(({ name, link, icon }) => {
          return (
            <MenuItem
              key={link}
              selected={location.pathname === link}
              onClick={(e) => {
                e.preventDefault()
                if (location.pathname !== link) navigate(link)
              }}>
              <Tooltip title={name}>
                <ListItemIcon>{icon}</ListItemIcon>
              </Tooltip>
              {isFull && <ListItemText>{name}</ListItemText>}
            </MenuItem>
          )
        })}
      </MenuList>
    </Box>
  )
}

export default SideAdminMenu
